
import './App.css';
import { Routing } from './Routes/Routing';

function App() {
  return (
   <>
   <Routing/>
   
   </>
  );
}

export default App;
