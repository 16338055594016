import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import './index.css';
import App from './App';
// import { uuidv4 } from './Component/utils';

//const ENDPOINT_URL = "https://ksg.intech-mena.com/MSG/v1.1/API/GetScript";

// const addScript = async () => {
//     let evinaRequestId = uuidv4();

//     const getScriptURL = `${ENDPOINT_URL}?applicationId=193&countryId=207&requestId=${evinaRequestId}`;

//     try {
//         const response = await fetch(getScriptURL);

//         if (!response.ok) {
//             throw new Error(`Failed to fetch script: ${response.statusText}`);
//         }

//         const scriptContent = await response.json();

//         const script = document.createElement('script');
//         script.text = scriptContent[100];
//         document.head.prepend(script);

//         const event = new Event('DCBProtectRun');
//         console.log('script ', event);
//         document.dispatchEvent(event);
//     } catch (error) {
//         console.error('Error loading script', error);
//     }
// };

//addScript();

const renderApp = () => {
    const root = createRoot(document.getElementById('root')); // Use createRoot from react-dom/client
    root.render(<App />);
};

renderApp();
